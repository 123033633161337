export const environment = {
  production: false,
  baseURL:
    'https://test-flightload.adminmodules.etihad.ae/ada-services/flightloadadmin',
  OKTAClientID: '0oa25pobtx58TKfWO0h8',
  OKTAIssuer: 'https://etihad.oktapreview.com',
  OKTArediRectUri:
    'https://test-flightload.adminmodules.etihad.ae/login/callback',
  coreDataURL: 'https://test.etihad.com',
  rmToolCookieName: 'rm-tool-test-jwt',
  env: 'TEST',
  secretKeyEmail: 'rev-man-email-key',
};
